import React, { useContext, useEffect, useState } from "react";
import "./Home.css";
import Nav from "../../components/Nav/Nav";
import BigCard from "../../share/BigCard/BigCard";
import { DataContext } from "../../context/DataContext";

const Home = () => {
  const { featuredProducts } = useContext(DataContext); 
  const [mobileImg, setMobileImg] = useState(false);
  const [product, setProduct] = useState(null);


  useEffect(() => {
    featuredProducts && setProduct(featuredProducts);
  }, [featuredProducts]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setMobileImg(true);
    } else {
      setMobileImg(false);
    }
  }, []);


  return (
    <div className="home-container">
      <div className="home-content">
        <Nav />

        <div className="home-main-img">
          <img
            src={
              mobileImg
                ? "https://i.hizliresim.com/dg0u693.jpg"
                : "https://i.hizliresim.com/b20tyhj.jpg"
            }
            alt=""
          />
        </div>

        <div className="home-marquee-text">
          <div className="upper-marquee">
            <h1>yeni koleksiyonları keşfedin.</h1>
            <h1>yeni koleksiyonları keşfedin.</h1>
            <h1>yeni koleksiyonları keşfedin.</h1>
          </div>

          <div className="lower-marquee">
            <h1>yeni koleksiyonları keşfedin.</h1>
            <h1>yeni koleksiyonları keşfedin.</h1>
            <h1>yeni koleksiyonları keşfedin.</h1>
          </div>
        </div>

        <div className="home-featured-container">
          <div className="home-featured-content">
            {product && product.map((item, idx) => {
              return (
                <BigCard
                  cardId={`card-${item.id}`}
                  name={item.name}
                  img={item.image}
                  price={item.price}
                  link={item.link}
                  small1={item.colors ? item.colors[0].image : null}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

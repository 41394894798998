import React from 'react'
import Nav from '../../components/Nav/Nav'
import './Shop.css'
import ProductList from '../../components/ProductList/ProductList'

const Shop = () => {
  return (
    <div className='shop-container'>
      <div className="shop-content">
        <Nav />
        <ProductList />
      </div>
    </div>
  )
}

export default Shop
import React from "react";
import "./About.css";
import Nav from "../../components/Nav/Nav";

const About = () => {
  return (
    <div className="about-container">
      <Nav />
      <div className="about-content">
        <div className="about-title">
          <h1>hakkımızda</h1>
        </div>

        <div className="about-tags">
          <div className="about-tags-item">
            <img src="https://resmim.net/cdn/2024/04/22/fbwf82.png" alt="" />
            <h1>Kalite</h1>
            <p>Yüksek kaliteli ünlü markaları keşfedin</p>
          </div>

          <div className="about-tags-item">
            <img src="https://resmim.net/cdn/2024/04/22/fbwZZ8.png" alt="" />
            <h1>Hız</h1>
            <p>
              Siparişlerinizi hızlı bir şekilde kapınıza kadar ulaştırıyoruz
            </p>
          </div>

          <div className="about-tags-item">
            <img src="https://resmim.net/cdn/2024/04/22/fbwNHF.png" alt="" />
            <h1>Çeşitlilik</h1>
            <p>Her zevke ve her bütçeye uygun geniş ürün yelpazesi</p>
          </div>

          <div className="about-tags-item">
            <img src="https://resmim.net/cdn/2024/04/22/fbwwTG.png" alt="" />
            <h1>Fiyat</h1>
            <p>En uygun fiyatlarla en kaliteli ürünleri satın alın</p>
          </div>
        </div>

        <div className="about-bg"></div>

        <div className="about-text">
          <img src="https://resmim.net/cdn/2024/04/22/fbjaOj.png" alt="" />
          <p>
            Merter'in kalbinde konumlanan mağazamız, dünya çapında tanınmış
            markaların son moda ürünlerini, her biri etiketli ve barkodlu
            olarak, toptan ve perakende seçenekleriyle sizlere sunuyor.
            Yenilikçi koleksiyonlarımızı, en rekabetçi fiyatlarla siz değerli
            müşterilerimize ulaştırırken, moda tutkunlarını benzersiz alışveriş
            deneyimleriyle buluşturuyoruz. Her bir üründe kalite ve şıklığı bir
            arada arayanlar için ideal bir destinasyon olmaya devam ediyoruz.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;

import React from "react";
import "./Footers.css";

const footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="footer-col1">
          <img src="https://i.hizliresim.com/d2y2p5c.png" alt="" />
          <p>Daha fazla ürün keşfetmek için shopify adresimizi ziyaret edin.</p>
        </div>

        <div className="footer-col2">
          <h3>İletişim</h3>
          <ul>
            <li>
              <span>+90 546 434 42 36</span>
            </li>
            <li>
              <span>wearkant@gmail.com</span>
            </li>
          </ul>
        </div>

        <div className="footer-col3">
          <h3>Social</h3>
          <ul>
            <li>
              <a href="https://www.instagram.com/kantwear_tr/" target="_blank">İnstagram</a>
            </li>
            <li>
              <a href="https://www.shopier.com/wearkanttr">Shopier</a>
            </li>
            <li><a href="https://api.whatsapp.com/send?phone=905464344236" target="_blank">Whatsapp</a></li>
          </ul>
        </div>
      </div>

      <div className="footer-signature">
        <p>© 2024 MEN AGENCY tarafından geliştirilmiştir</p>
      </div>
    </div>
  );
};

export default footer;

import React, { useContext, useEffect, useRef, useState } from "react";
import "./ProductPage.css";
import { useParams } from "react-router-dom";
import Nav from "../../components/Nav/Nav";
import Products from "../../data/Products";
import SmallCard from "../../share/SmallCard/SmallCard";
import { DataContext } from "../../context/DataContext";

const ProductPage = () => {
  const { products } = useContext(DataContext);
  const params = useParams();
  const [product, setProduct] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [smallImages, setSmallImages] = useState(null);
  const [randomProducts, setRandomProducts] = useState(null);
  const similarProductListRef = useRef(null);
  let startX;
  let scrollLeft;
  const slider = similarProductListRef.current;
  let isDown = false;

  useEffect(() => {
    if (products && params.id && products.length > 0) {
      setProduct(products[params.id - 1]);
      setMainImage(products[params.id - 1].image);
      setSmallImages(products[params.id - 1].colors);
    }
  }, [products, params.id]);

  const handleChangeSmallImages = (color) => {
    setMainImage(color);
    const updatedSmallImages = smallImages.filter(
      (item) => item.image !== color
    );
    setSmallImages([...updatedSmallImages, { image: mainImage }]);
  };

  const handleNext = () => {
    if (similarProductListRef.current) {
      similarProductListRef.current.scrollBy({
        left: 400,
        behavior: "smooth",
      });
    }
  };

  const handlePrev = () => {
    if (similarProductListRef.current) {
      similarProductListRef.current.scrollBy({
        left: -400,
        behavior: "smooth",
      });
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 3;
    slider.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    isDown = false;
    slider.classList.remove("active");
  };

  const handleMouseLeave = () => {
    isDown = false;
    slider.classList.remove("active");
  };

  useEffect(() => {
    const randomProducts = Products.sort(() => 0.5 - Math.random()).slice(0, 7);
    setRandomProducts(randomProducts);
  }, []);

  return (
    <div className="productPage-container">
      <Nav />
      <div className="productPage-content">
        <div className="product-images">
          {mainImage && (
            <div className="main-image">
              <img src={mainImage} alt="" />
            </div>
          )}

          <div className="small-images">
            {smallImages &&
              smallImages.map((color) => (
                <img
                  src={color.image}
                  alt=""
                  onClick={() => {
                    handleChangeSmallImages(color.image);
                  }}
                />
              ))}
          </div>
        </div>

        {product && (
          <div className="product-details">
            <h2>{product.name}</h2>
            <p className="product-details-price">₺{product.price}</p>
            <div className="product-desc">
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est,
                mollitia dignissimos odit ea velit pariatur harum delectus iste
                earum excepturi.
              </p>
            </div>
            <button>Satın AL</button>
            <div className="product-details-info">
              <p>
                Marka : <span>Armani</span>
              </p>
              <p>
                Kategori : <span>T-shirt</span>
              </p>
            </div>

            <div className="procut-details-cargo">
              <div className="product-details-cargo-item">
                <img
                  src="https://resmim.net/cdn/2024/04/22/fYsokk.png"
                  alt=""
                />
                <p>24 saat içinde hızlı kargo İmkanı</p>
              </div>

              <div className="product-details-cargo-item">
                <img
                  src="https://resmim.net/cdn/2024/04/27/frWUL7.png"
                  alt=""
                />
                <p>Taksitli ödeme seçenekleri</p>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="product-description">
        <div className="product-description-title">
          <h2>Ürün Açıklaması</h2>
        </div>

        <div className="product-description-text">
          <p>
            Hızlı ve güvenli alışverişin adresi olarak, ben giyim markası olarak
            24 saat içinde kargolama hizmeti sunuyoruz. Yüksek kaliteli
            ürünlerimizle tarzınızı tamamlayın ve her an şıklığınızı yansıtın.
            Müşteri memnuniyetini önceliğimiz olarak kabul ediyoruz ve her
            alanda mükemmelliği hedefliyoruz. Kolay iade ve değişim
            imkanlarımızla, alışveriş deneyiminizi sorunsuz hale getiriyoruz.
            Modanın öncüsü olarak, her sezonun trendlerini sizin için yakalıyor
            ve özgün tasarımlarımızla sizi memnun etmeye devam ediyoruz.
          </p>
        </div>
      </div>

      <div className="similar-product">
        <h2>Benzer Ürünler</h2>
        <div
          className="similar-product-list"
          ref={similarProductListRef}
          onMouseDown={(e) => {
            isDown = true;
            slider.classList.add("active");
            handleMouseDown(e);
          }}
          onMouseMove={(e) => {
            handleMouseMove(e);
          }}
          onMouseUp={() => {
            handleMouseUp();
          }}
          onMouseLeave={() => {
            handleMouseLeave();
          }}
        >
          {randomProducts &&
            randomProducts.map((product) => (
              <div className="similar-product-item">
                <SmallCard
                  key={product.id}
                  name={product.name}
                  price={product.price}
                  image={product.image}
                  id={product.id}
                />
              </div>
            ))}
        </div>
        <button className="similar-product-list-next">
          <img
            src="https://resmim.net/cdn/2024/04/22/fYi4PI.png"
            alt=""
            onClick={() => {
              handleNext();
            }}
          />
        </button>
        <button className="similar-product-list-prev">
          <img
            src="https://resmim.net/cdn/2024/04/22/fYi03R.png"
            alt=""
            onClick={() => {
              handlePrev();
            }}
          />
        </button>
      </div>
    </div>
  );
};

export default ProductPage;

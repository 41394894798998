import React from 'react'
import './Contact.css'
import Nav from '../../components/Nav/Nav'

const Contact = () => {
  return (
    <div className='contact-container'>
        <Nav />
        <div className="contact-content">
            <div className="contact-title">
                <h1>İletişim</h1>
            </div>

            <div className="contact-info">
                <div className="contact-info-text">
                    <h1>bizimle iletişime geç</h1>
                    <p>İstek, öneri ve şikayetleriniz için bizimle iletişime geçebilirsiniz.</p>
                    <h3>İstanbul</h3>
                    <h4>Mehmet Nesih Özmen Mahallesi, Gülsever Sokak</h4>
                    <h4>Merter/Güngören</h4>
                    <h4>wearkant@gmail.com</h4>
                    <h4>+90 546 434 42 36</h4>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact
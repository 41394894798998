import React, { useEffect, useRef, useState } from "react";
import "./BigCard.css";

const BigCard = ({
  cardId,
  img,
  link,
  price,
  name,
  small1,
  small2,
  small3,
  small4,
  small5,
}) => {
  const [show, setShow] = useState(false);
  const bigCardRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (bigCardRef.current && !bigCardRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (show) {
      document
        .querySelector(`.bigCard-small-show.${cardId}`)
        .classList.add("small-image-active");
    } else {
      document
        .querySelector(`.bigCard-small-show.${cardId}`)
        .classList.remove("small-image-active");
    }
  }, [show, cardId]);

  useEffect(() => {
    const images = document.querySelectorAll(
      `.bigCard-small-image.${cardId} img`
    );
    const showImage = document.querySelector(
      `.bigCard-small-show.${cardId} img`
    );

    images.forEach((image) => {
      image.addEventListener("click", () => {
        showImage.src = image.src;
        setShow(true);
      });
    });

    return () => {
      images.forEach((image) => {
        image.removeEventListener("click", () => {
          showImage.src = image.src;
          setShow(true);
        });
      });
    };
  }, [cardId, show]);

  useEffect(() => {
    const smallImages = document.querySelectorAll(
      `.bigCard-small-image.${cardId} img`
    );

    smallImages.forEach((img) => {
      if (img.src === undefined || img.src === "") {
        img.style.display = "none";
      }
    });
  }, [cardId]);

  return (
    <div className={`bigCard-container ${cardId}`} ref={bigCardRef}>
      <div className={`bigCard-content ${cardId}`}>
        <img className="bigCard-image" src={img} alt="" />
        <div className="bigCard-text">
          <h1>{name}</h1>
          <h4>{price} TL</h4>
          <a className="bigCard-link" href={link}>
            <img src="https://resmim.net/cdn/2024/04/20/fKoyJx.png" alt="" />
          </a>
        </div>

        <div className={`bigCard-small-image ${cardId}`}>
          <img src={small1} alt="" />
          <img src={small2} alt="" />
          <img src={small3} alt="" />
          <img src={small4} alt="" />
          <img src={small5} alt="" />
        </div>

        <div className={`bigCard-small-show ${cardId}`}>
          <img src="" alt="" />
          {show && (
            <button
              onClick={() => {
                setShow(!show);
              }}
              className="bigCard-small-show-close"
            >
              <img src="https://i.hizliresim.com/bmjneof.png" alt="" />
            </button>
          )}
        </div>
        {show && <div className="close-bg"></div>}
      </div>
    </div>
  );
};

export default BigCard;

import React, { useEffect, useRef, useState } from "react";
import "./SmallCard.css";

const SmallCard = ({name, price, image, id}) => {

  const [hover, setHover] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    if (hover) {
      cardRef.current.classList.add("smallCard-image-hover-active");
    } else {
      cardRef.current.classList.remove("smallCard-image-hover-active");
    }
  
  }, [hover])

  return (
    <div className="smallCard-container">
      <div className="smallCard-content">
        <div className="smallCard-image" onMouseOver={() => {
          setHover(true);
        }} onMouseLeave={() => {
          setHover(false);
        }}>
          <img src={image} alt="" />
          <a href={`/shop/product/${id}`} className="smallCard-image-hover" ref={cardRef}>
            <h1>incele</h1>
          </a>
        </div>

        <div className="smallCard-info">
          <div className="smallCard-name">
            <p>{name}</p>
          </div>
          <div className="smallCard-price">
            <p>₺ {price}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmallCard;

import { createContext, useEffect, useState } from "react";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [products, setProducts] = useState();
  const [featuredProducts, setFeaturedProducts] = useState();

  useEffect(() => {
    fetch(
      "https://raw.githubusercontent.com/KantWear/database/main/tum_urunler.json"
    )
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
      });
  }, []);

  useEffect(() => {
    fetch(
      "https://raw.githubusercontent.com/nuribostan/kantwear/main/one_cikanlar.json"
    )
      .then((response) => response.json())
      .then((data) => {
        setFeaturedProducts(data);
      });
  }, []);


  return <DataContext.Provider value={{
    products, setProducts, featuredProducts, setFeaturedProducts
  }}>{children}</DataContext.Provider>;
};

import React, { useContext, useEffect, useState } from "react";
import SmallCard from "../../share/SmallCard/SmallCard";
import "./ProductList.css";
import { DataContext } from "../../context/DataContext";

const ProductList = () => {
  const { products } = useContext(DataContext);
  const [product, setProduct] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    products && setProduct(products);
  }, [products]);

  useEffect(() => {
    if (product) {
      if (search) {
        setProduct(
          product.filter((item) =>
            item.name.toLowerCase().includes(search.toLowerCase())
          )
        );
      } else {
        setProduct(products);
      }
    }
  }, [search, product, products]);

  return (
    <div className="productList-container">
      <div className="productList-content">
        <div className="productList-header">
          {product && <h1>{product.length} ürün listeleniyor</h1>}
          <div className="productList-search-bar">
            <input
              type="text"
              placeholder="Ara..."
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            <img src="https://resmim.net/cdn/2024/04/20/fKpCJ1.png" alt="" />
          </div>
        </div>
        <div className="productList-list">
          {product && product.map((product) => (
            <SmallCard
              key={product.id}
              name={product.name}
              price={product.price}
              image={product.image}
              id={product.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductList;

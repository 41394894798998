import React, { useEffect, useState } from "react";
import "./Nav.css";

const Nav = () => {
  const [mobileNav, setMobileNav] = useState(false);
  const [rightSideMenu, setRightSideMenu] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const navItems = document.querySelectorAll(".nav-mid-col ul li");
    const navMobileItems = document.querySelectorAll(".nav-mobile-item");
    navItems.forEach((item) => {
      if (item.id === currentPath.split("/")[1]) {
        item
          .querySelector(".menu-item-line")
          .classList.add("menu-item-line-active");
      } else if (currentPath === "/" && item.id === "home") {
        item
          .querySelector(".menu-item-line")
          .classList.add("menu-item-line-active");
      }
    });

    navMobileItems.forEach((item) => {
      if (item.id === currentPath.split("/")[1] + "_m") {
        item
          .querySelector(".mobile_menu-item-line")
          .classList.add("mobile_menu-item-line-active");
      } else if (currentPath === "/" && item.id === "home_m") {
        item
          .querySelector(".mobile_menu-item-line")
          .classList.add("mobile_menu-item-line-active");
      }
    });
  });

  return (
    <div className="nav-container">
      <div className={`nav-mobile ${mobileNav ? "nav-mobile-active" : ""}`}>
        <div
          className="nav-mobile-close"
          onClick={() => {
            setMobileNav(!mobileNav);
          }}
        >
          <img src="https://resmim.net/cdn/2024/04/21/fKzr5n.png" alt="" />
        </div>
        <div className="nav-mobile-content">
          <a id="home_m" href="/" className="nav-mobile-item">
            Anasayfa
            <span className="mobile_menu-item-line"></span>
          </a>
          <a id="shop_m" href="/shop" className="nav-mobile-item">
            Mağaza
            <span className="mobile_menu-item-line"></span>
          </a>
          <a id="about_m" href="/about" className="nav-mobile-item">
            Hakkımızda
            <span className="mobile_menu-item-line"></span>
          </a>
          <a id="contact_m" href="/contact" className="nav-mobile-item">
            İletişim
            <span className="mobile_menu-item-line"></span>
          </a>
        </div>
      </div>
      <a href="/" className="nav-logo">
        <img src="https://i.hizliresim.com/d2y2p5c.png" alt="" />
      </a>

      <div className="nav-content">
        <div className="nav-left-col">
          <a
            href="https://www.instagram.com/kantwear_tr/"
            target="_blank"
            className="nav-left-col-item"
          >
            <img src="https://i.hizliresim.com/5hkjrbz.png" alt="" />
          </a>
          <a
            href="
https://www.shopier.com/wearkanttr"
            className="nav-left-col-item"
          >
            <img src="https://i.hizliresim.com/ao2vq8k.png" alt="" />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=905464344236"
            target="_blank"
            className="nav-left-col-item"
          >
            <img src="https://i.hizliresim.com/p3fdckh.png" alt="" />
          </a>
          <a href="/" className="nav-left-col-logo">
            <img src="https://i.hizliresim.com/d2y2p5c.png" alt="" />
          </a>
        </div>

        <div className="nav-mid-col">
          <ul>
            <li id="home">
              <a href="/">Anasayfa</a> <span className="menu-item-line"></span>
            </li>
            <li id="shop">
              <a href="/shop">Mağaza</a>
              <span className="menu-item-line"></span>
            </li>
            <li id="about">
              <a href="/about">Hakkımızda</a>
              <span className="menu-item-line"></span>
            </li>
            <li id="contact">
              <a href="/contact">İletişim</a>
              <span className="menu-item-line"></span>
            </li>
          </ul>
        </div>

        <div className="nav-right-col">
          <img style={{ opacity: 0 }} src="" alt="" />
          <img
            className="nav-right-col-mobile-nav"
            src="https://resmim.net/cdn/2024/04/21/fKqrWG.png"
            alt=""
            onClick={() => {
              setMobileNav(!mobileNav);
            }}
          />
          <img
            onClick={() => setRightSideMenu(!rightSideMenu)}
            className="show-contact"
            src="https://resmim.net/cdn/2024/04/21/fKqrWG.png"
            alt=""
          />
        </div>
      </div>
      <div
        className={`right-side-menu ${
          rightSideMenu ? "right-side-menu-active" : ""
        }`}
      >
        <div
          className="right-side-menu-closeButton"
          onClick={() => {
            setRightSideMenu(!rightSideMenu);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="qodef-side-area-svg-close"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path d="M1.325 15.14L15.467.996l1.414 1.415L2.739 16.554z"></path>
            <path d="M2.564 1.11l14.142 14.142-1.414 1.414L1.15 2.524z"></path>
          </svg>
        </div>

        <div className="right-side-menu-content">
          <div className="right-side-menu-logo">
            <img src="https://i.hizliresim.com/d2y2p5c.png" alt="" />
          </div>
          <p>Her bir üründe kalite ve şıklığı bir arada arayanlar için</p>
          <div className="nav-left-col">
          <a
            href="https://www.instagram.com/kantwear_tr/"
            target="_blank"
            className="nav-left-col-item"
          >
            <img src="https://i.hizliresim.com/5hkjrbz.png" alt="" />
          </a>
          <a
            href="
https://www.shopier.com/wearkanttr"
            className="nav-left-col-item"
          >
            <img src="https://i.hizliresim.com/ao2vq8k.png" alt="" />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=905464344236"
            target="_blank"
            className="nav-left-col-item"
          >
            <img src="https://i.hizliresim.com/p3fdckh.png" alt="" />
          </a>
          <a href="/" className="nav-left-col-logo">
            <img src="https://i.hizliresim.com/d2y2p5c.png" alt="" />
          </a>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
